exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catering-js": () => import("./../../../src/pages/catering.js" /* webpackChunkName: "component---src-pages-catering-js" */),
  "component---src-pages-diagnosis-js": () => import("./../../../src/pages/diagnosis.js" /* webpackChunkName: "component---src-pages-diagnosis-js" */),
  "component---src-pages-forugranola-js": () => import("./../../../src/pages/forugranola.js" /* webpackChunkName: "component---src-pages-forugranola-js" */),
  "component---src-pages-harajuku-js": () => import("./../../../src/pages/harajuku.js" /* webpackChunkName: "component---src-pages-harajuku-js" */),
  "component---src-pages-head-office-js": () => import("./../../../src/pages/head-office.js" /* webpackChunkName: "component---src-pages-head-office-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-menu-js": () => import("./../../../src/pages/LPMenu.js" /* webpackChunkName: "component---src-pages-lp-menu-js" */),
  "component---src-pages-office-shibuya-js": () => import("./../../../src/pages/office-shibuya.js" /* webpackChunkName: "component---src-pages-office-shibuya-js" */),
  "component---src-pages-office-tokyo-world-js": () => import("./../../../src/pages/office-tokyo-world.js" /* webpackChunkName: "component---src-pages-office-tokyo-world-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-original-order-js": () => import("./../../../src/pages/original_order.js" /* webpackChunkName: "component---src-pages-original-order-js" */),
  "component---src-pages-result-js": () => import("./../../../src/pages/result.js" /* webpackChunkName: "component---src-pages-result-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-voice-js": () => import("./../../../src/pages/Voice.js" /* webpackChunkName: "component---src-pages-voice-js" */)
}

